console.clear()

import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as dat from 'dat.gui'
import {gsap} from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);


/**
 * Base
 */

let sceneReady = false
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
const textureLoader = new THREE.TextureLoader()
const gltfLoader = new GLTFLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()

/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.envMapIntensity = 5
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
            //child.material.wireframe = true
        }
    })
}



/**
 * Material
 */

//const sphereMaterial = new THREE.MeshStandardMaterial({ wireframe:true})
/* const sphereMaterial = new THREE.MeshBasicMaterial({ 
  wireframe:true,
  color: 0x28cc9e
}) */
// Textures

/**
 * Models
 */

/* const sphereGeometry = new THREE.SphereGeometry(3, 40, 40)
const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial)
scene.add(sphere) */

let dna

gltfLoader.load('models/homemade_dna_split.glb',
(gltf) =>
{
    dna = gltf.scene
    
    dna.scale.set(0.12, 0.12, 0.12)
    dna.rotation.z = Math.PI * 0.5
    dna.rotation.x = Math.PI * 0.2
    dna.position.set(1, 0, -3)
    scene.add(dna)
    sceneReady = true

    updateAllMaterials()
    gsapAnimation()
})
/**
 * GSAP
 */
function gsapAnimation() {
  gsap.to(dna.position, {
    scrollTrigger: {
      trigger: "#section_two", 
      scrub: true,
      pin: true,
      toggleClass: {targets: "#sec-two-half", className: "active"},
      markers: false
    }, // start the animation when "#section_two" enters the viewport (once)
    x:0.5,
    z: 1
  })

  gsap.to(dna.position, {
    immediateRender: false,
    scrollTrigger: {
      trigger: "#section_three", 

      scrub: true,
      pin: true,
      toggleClass: {targets: "#sec-three-half", className: "active"},
      markers: false
    }, // start the animation when "#section_three" enters the viewport (once)
    x: 0,
    y: 1,
    z: -1
  })

  gsap.to(dna.rotation, {
    scrollTrigger: {
      trigger: "#section_four", 
      scrub: true,
      pin: true,
      toggleClass: {targets: "#sec-four-half", className: "active"},
      markers: false
    }, // start the animation when "#section_four" enters the viewport (once)
    x: Math.PI/2,
    
  })

  gsap.to(dna.rotation, {
    scrollTrigger: {
      trigger: "#section_five", 
      scrub: true,
      pin: true,
      toggleClass: {targets: "#sec-five-half", className: "active"},
      markers: false
    }, // start the animation when "#section_four" enters the viewport (once)
    z: Math.PI/4,
    
  })
}

/**
 * Lights
 */

const ambientLight = new THREE.AmbientLight('#ffffff', 0.15)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.normalBias = 0.05
directionalLight.position.set(4, 2, 4)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 1, 5)
scene.add(camera)

// Controls
 //const controls = new OrbitControls(camera, canvas)
//controls.enableDamping = true 

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFShadowMap
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    //controls.update()
    //dna.rotation.y += 0.001
if (sceneReady === true){
  dna.rotation.y += 0.003
}

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()